<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>


            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">



                    <article data-history-node-id="1075"
                             role="article"
                             about="/content/centurion-experience"
                             class="node node--type-centurion-page node--view-mode-full">





                        <div class="node__content">

                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">


                                    <div
                                         class="paragraph paragraph--type--block-reference paragraph--view-mode--default">


                                        <div
                                             class="field field--name-field-block-ref field--type-entity-reference field--label-hidden field__item">
                                            <div id="block-centurionexperiencesoffers"
                                                 class="block block-block-content block-block-content8194d79d-7a70-4a78-998a-545282d42555">



                                                <div
                                                     class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                                                    <div class="gray-white">
                                                        <div class="container">
                                                            <div class="row g-md-0 black-bg">
                                                                <div class="col-md-5">
                                                                    <div class="title">{{ $t(`Centurion`) }}<br />
                                                                        {{ $t(`experiences & offers`) }}</div>
                                                                </div>

                                                                <div class="col-md-7">
                                                                    <div class="image"><img
                                                                             alt="Centurion experiences & offers logo"
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/cent-exp-and-offers.jpg`)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-experiences-offers paragraph--view-mode--default color-black">
                                        <div class="cent-gray-bg">
                                            <div class="container">
                                                <div class="row g-md-0">
                                                    <div
                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                        <div class="cent-exp-offers mw-100">

                                                            <div
                                                                 class="field field--name-field-cent-experiences-offer-img field--type-image field--label-hidden field__item">
                                                                <img :src="require(`@/assets/images/centurion/centurion-exxperience-banner.jpg`)"
                                                                     width="1300"
                                                                     height="543"
                                                                     alt=""
                                                                     loading="lazy"
                                                                     typeof="foaf:Image"
                                                                     class="img-fluid" />

                                                            </div>

                                                            <SubMenu></SubMenu>

                                                            <div
                                                                 class="field field--name-field-cent-offers field--type-entity-reference-revisions field--label-hidden field__items">
                                                                <div class="field__item">
                                                                    <div
                                                                         class="paragraph paragraph--type--cent-offers-container paragraph--view-mode--default">
                                                                        <div class="row margin justify-content-center">
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/centurion-living.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Centurion Living
                                                                                            Season 2025`) }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Welcome To the
                                                                                            Immersive Art of Centurion
                                                                                            Living — Beautifully
                                                                                            Inspired
                                                                                            By You`) }}</p>

                                                                                        <p>{{ $t(`To embrace the art of
                                                                                            living is to discover the
                                                                                            beauty, culture and
                                                                                            diversity of the world
                                                                                            —through experiences curated
                                                                                            exclusively for
                                                                                            Centurion. This season, let
                                                                                            us immerse you in some of
                                                                                            the world’s most
                                                                                            exciting culture, settings,
                                                                                            and experiences, while you
                                                                                            connect and share
                                                                                            with likeminded Centurion
                                                                                            members. Discover`) }} <a
                                                                                                v-if="!showMore"
                                                                                                style="color: #0d6efd;"
                                                                                               @click="showMore=!showMore"  
                                                                                               id="showbtn">{{
                                                                                                $t(`more`) }}</a>. <span
                                                                                                  v-if="showMore"
                                                                                                  class="showmore-txt"
                                                                                                  style="display: block;">{{
                                                                                                    $t(`Centurion living
                                                                                                opens a world of
                                                                                                exclusive
                                                                                                experiences, expertly
                                                                                                designed to expand your
                                                                                                horizons, and satisfy
                                                                                                your
                                                                                                spirit of discovery.
                                                                                                With Centurion Living
                                                                                                Destinations, embark on
                                                                                                thoughtfully curated
                                                                                                itineraries that guide
                                                                                                you through the most
                                                                                                exquisite culture,
                                                                                                entertainment and
                                                                                                dining, and
                                                                                                gain-privileged access
                                                                                                to celebrated tastings
                                                                                                guided by experts. We
                                                                                                hope you will enjoy it!
                                                                                                For
                                                                                                more information and
                                                                                                booking, Please call
                                                                                                your Centurion
                                                                                                Relationship
                                                                                                Manager or Click`) }} <a
                                                                                                   href="https://www.americanexpress.com/en-gl/benefits/new-centurion/living"
                                                                                                    target="_blank"
                                                                                                   >{{
                                                                                                    $t(`Here`)
                                                                                                }}</a>.
                                                                                                <a
                                                                                                v-if="showMore"
                                                                                                style="color: #0d6efd;"
                                                                                               @click="showMore=!showMore"  
                                                                                               id="showbtn">
                                                                                               {{ $t('less') }}
                                                                                                </a>
                                                                                                </span>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/new-hekaya.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Hekaya Africa`) }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Earn 8X Membership
                                                                                            Rewards® points on any
                                                                                            Hekaya Travel experience
                                                                                            booked
                                                                                            through your Centurion
                                                                                            Relationship Manager and our
                                                                                            Lifestyle Premium
                                                                                            Services using your
                                                                                            Centurion Card. Journey into
                                                                                            the wild and let each
                                                                                            experience tell its own
                                                                                            story. Terms and Conditions
                                                                                            apply.`) }}</p>

                                                                                        <p>{{ $t(`Offer Valid till 31
                                                                                            May 2025.`) }}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-cent-offer-text field--type-text-long field--label-hidden field__item">
                                                                <ul>
                                                                    <li>{{ $t(`All offers, benefits, and promotions are
                                                                        subject to availability and may change
                                                                        without prior notice. While we strive to provide
                                                                        accurate and up-to-date
                                                                        information, American Express Saudi Arabia does
                                                                        not guarantee the ongoing
                                                                        availability or validity of offers displayed on
                                                                        this website.`) }}</li>
                                                                    <li>{{ $t(`The website content is regularly reviewed
                                                                        and updated; however, due to the dynamic
                                                                        nature of promotions, some offers may expire or
                                                                        change before updates are reflected.
                                                                        For accurate and current information, please
                                                                        contact your Centurion Relationship
                                                                        Manager or call`) }} <a
                                                                           href="tel:800 122 5225">800 122 5225</a>.
                                                                    </li>
                                                                    <li>{{ $t(`American Express Saudi Arabia reserves
                                                                        the right to modify, replace, or terminate
                                                                        any offers, benefits, or promotions at any time
                                                                        without prior notice. Updates take
                                                                        effect immediately upon posting or on a
                                                                        specified date. By continuing to use the
                                                                        service, you agree to the terms of any
                                                                        changes.`) }}</li>
                                                                    <li>{{ $t(`The decision of American Express Saudi
                                                                        Arabia is final regarding disputes related
                                                                        to these offers and benefits. We are not liable
                                                                        for any discrepancies between the
                                                                        website information and the actual status of
                                                                        offers. For confirmation or specific
                                                                        inquiries, please contact your Centurion
                                                                        Relationship Manager or call`) }} <a
                                                                           href="tel:800 122 5225">800 122 5225</a>.
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </article>

                </div>

            </div>


        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>

document.addEventListener("DOMContentLoaded", function (event)
{
    (function ($)
    {
        $(document).ready(function ()
        {

            $("#showbtn").click(function (e)
            {
                e.preventDefault();
                $(".showmore-txt").show();
            });

        });
    })(jQuery);
});

import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';
import SubMenu from './sub-menu.vue'

export default {
    components: {
        CenturionHeader,
        CenturionFooter,
        SubMenu
    },
    data: () => ({
        showMore:false,
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.centtoctitle {
    color: #000000;
    font-size: 2em;
}

.centtoclist,
.centtoclist a,
.centtoclist a:link,
.centtoclist a:visited,
.centtoclist a:hover {
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
}

@media only screen and (max-width: 576px) {
    .centtoclist {
        margin-bottom: 1.5em;
    }
}

.gray-white {
    background: linear-gradient(to bottom, #fff 80%, #f8f8f8 20%);
}

.black-bg {
    background-color: #000000;
}

.title {
    font-size: 1.5em !important;
    font-weight: bold ;
    color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    transform: translateY(1.5em);
    text-align: center;
}

.image img {}

@media only screen and (max-width: 576px) {
    .black-bg {
        margin-bottom: 20%;
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-top: 1em;
    }

    .image {
        transform: translateY(50%);
        text-align: center;
        margin-top: -25%;
    }
}
</style>